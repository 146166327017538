import { WprValueMap } from '../../../../wpr-framework/model/WprValueMap';
import { WprValueMapSetter } from '../../../../wpr-framework/model/WprValueMapSetter';

/**
 * サービス一覧
 */
export class ServiceListValueMap {
	// static メソッド  --------------------------------------------------------
	public static addValueMap(vmSetter: WprValueMapSetter): void {
		let vm = new WprValueMap();
		vm.addValue('video_unlimited_comfirm', '動画_みるプラス：video_unlimited_comfirm');
		vm.addValue('video_ipstb_comfirm', '動画_IP-STB：video_ipstb_comfirm');
		vm.addValue('video_pass_comfirm', '動画_パスワード再発行：video_pass_comfirm');
		vm.addValue('add_nhk_select', 'NHK_追加：add_nhk_select');
		vm.addValue('nhk_edit', 'NHK_変更：nhk_edit');
		vm.addValue('mod_nhk_confirm', 'NHK_解約：mod_nhk_confirm');
		vm.addValue('itscom_home_select1', 'itscomHome_追加：itscom_home_select1');
		vm.addValue('itscom_home_revise_select', 'itscomHome_変更：itscom_home_revise_select');
		vm.addValue('add_accesspass_select', 'アクセスアカウント_変更：add_accesspass_select');
		vm.addValue('mod_ipop_confirm', 'IPアドレス種別選択オプション変更：mod_ipop_confirm');
		vm.addValue('add_net_select', 'ネット追加：add_net_select');
		vm.addValue('mod_net_select', 'ネット変更：mod_net_select');
		vm.addValue('rei_accesspass_confirm', 'アクセスアカウント_発行rei_accesspass_confirm');
		vm.addValue('add_phone_select', 'itscomPhone_追加：add_phone_select');
		vm.addValue('call_forwarding_select', '着信転送：call_forwarding_select');
		vm.addValue('magazine_cancel_confirm', 'マガジン_解約：magazine_cancel_confirm');
		vm.addValue('magazine_confirm', 'マガジン_追加：magazine_confirm');
		vm.addValue('add_tv_select1', 'テレビ_追加：add_tv_select1');
		vm.addValue('mod_tv_select1', 'テレビ_コース変更：mod_tv_select1');
		vm.addValue('mod_tvequip_select', 'テレビ_機器変更：mod_tvequip_select');
		vm.addValue('add_tvop_select', 'テレビ_オプション変更：add_tvop_select');
		vm.addValue('relocate_select1', '移設申込：relocate_select1');
		vm.addValue('mobile_confirm', 'かっとびモバイル_解約：mobile_confirm');
		vm.addValue('tokoton_support_select', 'とことんサポート：tokoton_support_select');
		vm.addValue('add_meshWifi_select', 'メッシュWiFi：add_meshWifi_select');
		vm.addValue('name_change_information', '名義変更旧名義人：name_change_information');
		vm.addValue('name_change_new_information', '名義変更新名義人：name_change_new_information');
		vm.addValue('line_liff', 'LINE：line_liff');;
		vm.addValue('constr_list', '工事申込み：constr_list');
		// vm.addValue('terminal_regist', '端末登録：terminal_regist');
		vmSetter.addValueMap('serviceList', vm);
	}
	// --------------------------------------------------------------------------
}
