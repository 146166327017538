import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { AuthService } from '../../../service/AuthService';
import { GetLineInfoRequest } from '../../../service/models/dto/auth/GetLineInfoRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import {liff} from '../../../../../scripts/liff.js';

/**
 * LINE同意承諾画面
 */
export class LineLiffView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LineLiffView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	private m_loginToken: string = null;
	// --------------------------------------------------------------------------
	private m_AuthService: AuthService = new AuthService();	// 認証サービス

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		const host = location.host;
		let liffId: string;
		if (host.includes("localhost") || host.includes("stg")) 
			liffId = '2001902820-6ZVWX0NP';
		else 
			liffId = '2005701465-K0rLypao';
		liff.init({
			liffId: liffId, // Use own liffId
			withLoginOnExternalBrowser: true,
		})
		.then(() => {
			const idToken = liff.getIDToken();
			const reqGetLineInfoRequest: GetLineInfoRequest = new GetLineInfoRequest();
			reqGetLineInfoRequest.idToken = idToken;
			this.m_AuthService.getLineInfo(this, reqGetLineInfoRequest, (result) => {
				if (result) {
					if (result.linkedFlg == false) {
						this.setCookie('idToken', result.loginToken);
						this.pushPath('/line_agree');
					} else {
						location.href = this.getOldUrl() + "Top/Login/";
					}
				} else {
					this.pushPath('/error');
				}
			});
		  }) .catch((err) => {
			this.pushPath('/error');
		});
	}
	// --------------------------------------------------------------------------
}
