import { IWprUrlParam } from "../../wpr-framework/view/route/IWprUrlParam";


/**
 * 画面ウィザード用モデル
 */
export class WizardModel {
	// public 変数	-----------------------------------------------------------
	public stepNum: number;					// ステップ番号
	public title: string;					// タイトル
	public viewName: string;				// 画面名
	public path: string;					// 画面遷移パス
	public params?: IWprUrlParam[];			// 画面遷移データ
	public isValid?: boolean=false;			// 画面遷移バリデ
	public useViewData?: boolean=false;		// 画面遷移
	// ------------------------------------------------------------------------
}
/**
 * 画面ウィザードPOSTデータ格納モデル
 */
export class WizardPostModel {
	// public 変数	-----------------------------------------------------------
	public static COOKIE_CUSTOMER_ID: string = 'customerId';			// 顧客ID

	public static URL_VIEW_ID: string = 'VIEW';				// ビューID
	public static URL_CONTRACT_ID: string = 'CONTRACT';		// 契約ID
	public static URL_OPTION_ID: string = 'OPTIONCD';		// オプションID
	public static URL_MAXFLG: string = 'MAXFLG';			// マックスサービスフラグ
	public static URL_ACOUNTID: string = 'ACOUNTID';		//アクセスアカウント
	public static URL_NCTOKEN: string = 'NCTOKEN';			//名義変更トークン
	public static URL_WORK_SCHEDULE_ID: string = 'WORK_SCHEDULE_ID';			//工事スケジュールID
	// public static URL_MAC_ADDRESS: string = 'MAC_ADDRESS';			//マックアドレス

	public viewId: string;					// ビューID	
	public customerId: string;				// 顧客ID
	public contractId: string;				// 契約ID
	public optionId: string;				// オプションID
	public maxFlg: string;					// マックスサービスフラグ
	public radiusAccount: string;			// アクセスアカウント
	public workScheduleId: string;			// 工事スケジュールID
	// public macAddress: string;				// マックアドレス
	// ------------------------------------------------------------------------
}