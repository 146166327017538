
/**
 *  エラーコード
 */
class MypageErrorConstants {
    
	/** 000~099 =========================================== */
	/** 名義変更エラー */
    NAMECHANGE_ERROR:string = '80001';
	/** セッションエラー */
    SESSION_ERROR:string = '90001';
	/** APIエラー */
    API_ERROR:string = '90002';
	/** 9000エラー */
    SERVICE_ERROR:string = '90003';
}

export default new MypageErrorConstants();