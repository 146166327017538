import { WprBaseViewMultiModeInfo } from '../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../wpr-framework/mode/WprMultiMode';
import { MenuView } from '../MenuView';
import { WprDI_Object } from '../../../../wpr-framework/view/di/WprDI_Object';
import { WizardPostModel } from '../../../models/WizardModel';

/**
 * ViewへGETパラメータで付与するデータを制御
 */
export class ViewPostMode extends WprBaseViewMultiModeInfo<MenuView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Object
	private m_WizardPostModel: WizardPostModel	= null;	// 画面ウィザードPOSTデータ格納モデル
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('ビュー送信データ制御モード');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('選択なし');
		list.push('契約ID送信');
		list.push('オプションID送信');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('contractId', { display: [ { mode: '契約ID送信', value: true }, { mode: 'other', value: false } ] });
		this.addControl('optionId', { display: [ { mode: 'オプションID送信', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		newMode.addMode('選択なし');

		// itscomHome_変更
		if (this.m_WizardPostModel.viewId === 'itscom_home_revise_select') {
			newMode.addMode('契約ID送信');
		}
		// itscomNet_変更コース選択
		if (this.m_WizardPostModel.viewId === 'mod_net_select') {
			newMode.addMode('契約ID送信');
		}
		// IPアドレス種別選択オプション変更
		if (this.m_WizardPostModel.viewId === 'mod_ipop_confirm') {
			newMode.addMode('契約ID送信');
			newMode.addMode('オプションID送信');
		}
		// アクセスアカウントパスワード変更
		if (this.m_WizardPostModel.viewId === 'add_accesspass_select') {
			newMode.addMode('契約ID送信');
		}
		// itscomHome_変更
		if (this.m_WizardPostModel.viewId === 'rei_accesspass_confirm') {
			newMode.addMode('契約ID送信');
		}
		
		// // 名義変更
		// if (this.m_WizardPostModel.viewId === 'name_change_information') {
		// 	newMode.addMode('オプションID送信');
		// }
		// if (this.m_WizardPostModel.viewId === 'name_change_form') {
		// 	newMode.addMode('オプションID送信');
		// }

		// かっとびモバイル解約
		if (this.m_WizardPostModel.viewId === 'mobile_confirm') {
			newMode.addMode('契約ID送信');
		}
		// テレビコース変更
		if (this.m_WizardPostModel.viewId === 'mod_tv_select1') {
			newMode.addMode('契約ID送信');
		}
		// テレビ機器変更
		if (this.m_WizardPostModel.viewId === 'mod_tvequip_select') {
			newMode.addMode('契約ID送信');
		}
		// テレビオプション変更
		if (this.m_WizardPostModel.viewId === 'add_tvop_select') {
			newMode.addMode('契約ID送信');
		}
		if (this.m_WizardPostModel.viewId === 'magazine_cancel_confirm') {
			newMode.addMode('契約ID送信');
		}
		if (this.m_WizardPostModel.viewId === 'magazine_confirm') {
			newMode.addMode('契約ID送信');
		}
		// 着信転送
		if (this.m_WizardPostModel.viewId === 'call_forwarding_select') {
			newMode.addMode('契約ID送信');
		}
		if (this.m_WizardPostModel.viewId === 'mod_nhk_confirm') {
			newMode.addMode('契約ID送信');
		}
		// vm.addValue('video_unlimited_comfirm', '動画_みるプラス：video_unlimited_comfirm');
		// vm.addValue('video_ipstb_comfirm', '動画_IP-STB：video_ipstb_comfirm');
		// vm.addValue('video_pass_comfirm', '動画_パスワード再発行：video_pass_comfirm');
		// vm.addValue('add_nhk_select', 'NHK_追加：add_nhk_select');
		// vm.addValue('mod_nhk_confirm', 'NHK_解約：mod_nhk_confirm');
		// vm.addValue('itscom_home_select1', 'itscomHome_追加：itscom_home_select1');
		// vm.addValue('itscom_home_revise_select', 'itscomHome_変更：itscom_home_revise_select');
		// vm.addValue('add_accesspass_select', 'アクセスアカウント_変更：add_accesspass_select');
		// vm.addValue('mod_ipop_confirm', 'IPアドレス種別選択オプション変更：mod_ipop_confirm');
		// vm.addValue('add_net_select', 'かっとび光オプション追加：add_net_select');
		// vm.addValue('mod_othernet_select', 'かっとび光オプション変更：mod_othernet_select');
		// vm.addValue('rei_accesspass_confirm', 'アクセスアカウント_発行rei_accesspass_confirm');
		// vm.addValue('add_phone_select', 'itscomPhone_追加：add_phone_select');
		// vm.addValue('magazine_cancel_confirm', 'マガジン_解約：magazine_cancel_confirm');
		// vm.addValue('magazine_confirm', 'マガジン_追加：magazine_confirm');
	}
	// --------------------------------------------------------------------------
}
